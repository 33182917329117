.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

b {
  font-family: "GucciSans-Bold" !important;
}


ul {
    padding: 0 60px !important;
}

.align_center{
  align-items: center;
}

.flex {
  display: -webkit-flex;
}

.capitalize {
  text-transform: capitalize;
}

.text_center {
  text-align: -webkit-center;
}

.self_center {
  align-self: center;
  text-transform: capitalize;
}

.bold {
  font-family: "GucciSans-Bold" !important;
}

.light {
  font-family: "GucciSans-Book" !important;
}

.ml--10 {
  margin-left: -10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

*,
::after,
::before {
  box-sizing: content-box !important;
}

.w-100 {
  width: 100%;
}

.w-85 {
  width: 85%;
}

.pointer {
  cursor: pointer
}

@font-face {
  font-family: 'GucciSans-Bold';
  src: url('./resources/fonts/GucciSans-Bold.otf');
  /* IE9 Compat Modes */
}

@font-face {
  font-family: 'GucciSans-Book';
  src: url('./resources/fonts/GucciSans-Book.otf');
  /* IE9 Compat Modes */
}


/* DDL */

.dropdown-toggle {
  background-color: #000 !important;
  border: none !important;
  box-shadow: none !important;
}

.dropdown-menu.show {
  display: block;
  transform: translate(-70px, 38px) !important;
}

.dropdown-toggle::after {
  margin-top: 0px !important;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border: none !important;
}

.gucci_text {
  font-family: 'GucciSans-Book';
}

.gucci_text_bold {
  font-family: 'GucciSans-Bold';
}

.red {
  color: #b40000;
}

.pl-5 {
  padding-left: 5px !important;
}

.iziToast-wrapper-bottomCenter {
  text-align: right !important;
}

.iziToast {
  border: none !important;
}

.iziToast-body {
  padding-right: 33px !important;

}

.iziToast>.iziToast-body .iziToast-texts {
  margin-top: 17px !important;

}

.btn-outline-error {
  background-color: #c04342 !important;
}

.btn-outline-success {
  background-color: #00a4e2 !important;
}

.btn-outline-error>.iziToast-body .iziToast-message {
  font-family: 'GucciSans-Book' !important;
  font-size: 15px !important;
  color: #ffffff !important
}

.btn-outline-success>.iziToast-body .iziToast-message {
  font-family: 'GucciSans-Book' !important;
  font-size: 15px !important;
  color: #ffffff !important
}

.btn-outline-error>.iziToast-close {
  opacity: 1 !important;
  filter: invert(1);
}

.btn-outline-success>.iziToast-close {
  opacity: 1 !important;
  filter: invert(1);
}

.iziToast>.iziToast-close {
  opacity: 1 !important;
}

/* NAVBAR */


.login_navbar {
  width: calc(100% - 20px);
  /*position: absolute;*/
  height: 30px;
  background-color: #000000;
  padding: 10px;
  box-shadow: 0 4px 15px 1px grey;
  margin-bottom: 20px;
  align-items: center;
}

.navbar_text {
  font-family: "BebasNeue-Bold";
  color: white;
  text-transform: uppercase;
  font-size: 26px;
}

.navbar_username {
  -webkit-appearance: none !important;
  font-family: "GucciSans-Bold";
  color: white;
  /*margin-right: 5px;*/
  margin-left: -10px;
  background: black;
  display: flex;
  border: none;
}

.dropdown-menu {
  /*left: -90px !important;*/
  width: 200px;
  top: 8px !important;
  position: absolute;
  right: -70px !important;
}

.dropdown-item {
  font-family: "GucciSans-Bold";
  width: 170px !important;
}

.dropdown-toggle::after {
  margin-left: 0.5em !important;
  vertical-align: middle !important;
  margin-top: 10px;
}

.language_text {
  position: absolute;
  right: 75px;
  color: white;
  font-family: 'GucciSans-Book';
  display: flex;
  align-items: center;
  width: 40px;
  height: 39px;
  z-index: 5;
  cursor: pointer;
}

.navbar_logo {
  background-image: url("./resources/img/logo_gucci.png");
  height: 30px;
  width: 100px;
  background-size: 100px;
  background-repeat: no-repeat;
  margin: auto;
  background-position: center;
  position: absolute;
  left: calc(50% - 50px);
  top: 10px;
}

.navbar_logout {
  margin-right: 20px;
}

.navbar_dropdown {
  position: absolute !important;
  right: 75px;
  /* top: 5px; */
}

.logout_icon {
  cursor: pointer;
  position: absolute !important;
  right: 10px;
  top: 5px;
}

.background {
  width: 100%;
  /*position: absolute;*/
  height: 100vh;
  background-color: #f5f5f5;
  overflow: hidden;
}

/* LOGIN PAGE */

.left_image {
  background-image: url('./resources/img/unicef_1.jpg');
  width: 45%;
  height: calc(100vh - 79px);
  position: relative;
  background-size: cover;
  background-position: center;
  float: left;
}

.right_content {
  width: 55%;
  position: absolute;
  float: right;
  right: 0;
}

.img_container {
  position: absolute;
  width: 98%;
  height: 90vh;
  overflow: hidden;
}

.pedal_logo {
  /* width: 70%; */
  height: 130px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.up_images_cont {
  margin-top: -140px;
  display: inherit;
  width: 100%;
  height: 155px;
}



.login_images_container {
  position: relative;
  margin-top: 3%;
  display: flex;
  width: 95%;
  justify-content: center;
}


.login_container {
  width: calc(100% - 30px);
  height: -webkit-fill-available;
  position: absolute;
  top: 50px;
  text-align: -webkit-center;
  border: 15px solid #00a4e2;
  /* position: fixed; */
  /* align-content: center; */
}

.login_info_container {
    /* width: 95%; */
    height: calc(100vh - 80px);
    /* background-image: linear-gradient(#0348b2, #00a4e2, #d60037); */
    /* background-color: #00a4e2; */
    background-color: #e0f6ff;
    /* border-radius: 20px; */
    overflow: hidden;
    /* padding: 10px; */
    /* margin-top: 70px; */
    /* margin: 50px auto; */
    /* margin-left: 30px; */
    /* position: absolute; */
}

.login_main_logo_container {
  margin-top: 15px;
}

.login_main_logo {
  background-image: url("./resources/img/gucci_changemakers_logo.png");
  width: 250px;
  background-size: 250px;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
}

.logos_container {
  width: 100%;
  text-align: -webkit-center;
  margin: 20px 0;
}

.gucci_logo_container {
  background-image: url("./resources/img/gucci_changemakers_bianco.png");
  background-image: url("./resources/img/gucci_changemakers_logo.png");
  height: 80px;
  width: 200px;
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 8%;
  margin-top: 15px;
}

.unhcr_logo_container {
  /* background-image: url("./resources/img/logo_unhcr.png");*/
  background-image: url("./resources/img/logo-unicef-no\ back.png");
  height: 90px;
  width: 260px;
  background-size: 260px;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px 0 0 15px;
}

.logo_separator {
  font-family: "GucciSans-Bold";
  color: white;
  font-size: 40px;
  margin: 10px 10px;
}

.title_container {
  /*margin-top: 50px;*/
  margin-top: 100px;
  width: 60%;
}

.login_title {
  font-family: "GucciSans-Book";
  color: rgb(239 247 252);
  font-size: 25px;
  position: relative;
  z-index: 5;
}

.login_subtitle {
  font-family: "GucciSans-Bold";
  color: white;
  text-transform: uppercase;
  font-size: 18px;
}

.main_title_container {
  width: 60%;
}

.login_main_title {
  font-family: "BebasNeue-Bold";
  color: white;
  color: black;
  font-size: 80px;
  line-height: 120px;
}

.not_auth_container {
  display: flex;
  align-items: center;
  height: 100%;
  place-content: center;
}

.not_auth_message {
  font-family: "GucciSans-Book";
  color: black;
  font-size: 30px;
}

.login_subtitle_2 {
  font-family: "GucciSans-Bold";
  color: rgb(239 247 252);
  font-size: 18px;
  margin-top: 20px;
  position: relative;
  z-index: 5;
}

.login_button {
  /*background-color: white;*/
  background-color: #00a4e2;
  width: 25%;
  height: 80px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 50px;
  padding: 10px;
  z-index: 2;
  position: relative;
    border-radius: 5px;
}

.login_button_mobile {
    /* background-color: white; */
    background-color: #00a4e2;
    width: 50%;
    height: 60px;
    border-radius: 0;
    cursor: pointer;
    margin-bottom: 50px;
    margin-top: 10px;
    padding: 10px;
    z-index: 2;
    position: relative;
    border-radius: 5px;
    /* display: none; */
}

.login_button_text {
  /*color: #00a4e2;*/
  color: #fff;
  font-family: "BebasNeue-Bold";
  font-size: 40px;
  margin-top: 0px;
}

.login_main_text {
  font-family: "BebasNeue-Bold";
  font-size: 32px;
  line-height: 32px;
  margin-top: 10px;
  color: black;
}

.login_text {
  color: white;
  color: black;
  font-family: "GucciSans-Book";
  font-size: 18px;
  text-align: justify;
  width: 100%;
  justify-self: left !important;

}

.login_ppolicy {
    color: rgb(0, 0, 0);
    font-family: "GucciSans-Book";
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 15px;
    text-decoration: underline;
}

.u424_container {
  background-image: url("./resources/img/u424.png");
  height: 400px;
  width: 600px;
  background-size: 600px;
  background-repeat: no-repeat;
  background-position: center;
  float: right;
  z-index: 1;
  position: absolute;
  bottom: 50px;
  right: 50px;
}

/* LOGIN CHECKBOX */

.isAcceptedCheckbox_container {
  margin-top: 3px;
  margin-bottom: -10px;
  z-index: 5;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
}

#isAcceptedCheckbox_label {
  font-family: "GucciSans-Book";
}

.isAcceptedCheckbox_container label {
  padding-right: 10px;
  margin-bottom: 0px !important;
}



.isAcceptedCheckbox_container label span {
  vertical-align: middle;
}

.label_checkbox_link {
  margin-left: 5px;
  font-style: italic;
}

/* HOMEPAGE */

.boxes_container {
  padding: 0px 5px;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
  /* height: 100vh;*/
  height: calc(100vh - 60px);
  margin-top: -10px;
}

.about_container {
  border-width: 0px;
  /* position: absolute; */
  left: 0px;
  top: 0px;
  width: 420px;
  height: 415px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
    box-shadow: 0 3px 6px #bfbfbf;
  padding: 10px;
  border-radius: 5px;
}

.logo_gucci_small_container {
  /**/
  background-image: url("./resources/img/gucci_changemakers_logo.png");
  height: 60px;
  width: 150px;
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: center;
}

.about_container_small {
  border-width: 0px;
  /* position: absolute; */
  left: 0px;
  top: 0px;
  width: 420px;
  /* height: 340px; */
  height: 367px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
  padding: 10px
}

.about_container_header {
  align-items: center;
  place-content: center;
}

.about_container_red {
  width: 420px;
  height: 415px;
  background-color: #fff;
    box-shadow: 0 3px 6px #bfbfbf;
  border-radius: 5px;
  padding: 10px;
}

.about_container_blu {
  width: 420px;
  height: 415px;
  background-color: #00a4e2;
  padding: 10px;
    box-shadow: 0 3px 6px #bfbfbf;
  border-radius: 5px;
}

.donate_container {
  width: 420px;
  height: 145px;
  background-color: #fff;
    box-shadow: 0 3px 6px #bfbfbf;
  padding: 10px;
  border-radius: 5px;
}

.publish_container {
  border-radius: 5px;
  position: relative;
  width: 420px;
  box-shadow: 0 3px 6px #bfbfbf;
  height: 240px;
  background-color: #00a4e2;
  padding: 10px;
  width: 420px;
  background-size: 370px 260px;
  background-repeat: no-repeat;
  -webkit-background-size: 370px 260px;
  -moz-background-size: 370px 260px;
  background-position: right;
    background-image: linear-gradient(to right, #00a4e2, rgba(0, 0, 0, 0)), url("./resources/img/unicef_2.jpg");
  /*background-image: linear-gradient(to left, rgba(0, 114, 188, 1), rgba(0, 0, 0, 0)), url("./resources/img/image006.png");*/
  /*background: -webkit-linear-gradient(left,rgba(0, 114, 188, 1), rgba(0, 0, 0, 0)), url("./resources/img/image006.png"); /* Chrome10-25,Safari5.1-6 */
}


.unhcr_challenge {
  /*
  padding: 0;
  width: 440px;
  */
  height: 415px;
}

.about_title {
  display: flex;
  font-family: "BebasNeue-Bold";
  font-size: 35px;
  color: #000000;
  /*margin-top: 10px;*/
  justify-content: center;
  margin-bottom: 5px;
}

.box_title {
  border-width: 0px;
  width: 405px;
  height: 30px;
  display: flex;
  font-family: "GucciSans-Bold", "Gucci Sans Bold", "Gucci Sans Book", "Gucci Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  text-align: left;
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 5px;
}

.box_title_red {
  font-family: "BebasNeue-Bold";
  font-size: 40px;
  color: #fff;
  text-align: center;
  margin-bottom: 5px;
}

.box_title_red_ja {
  font-family: "BebasNeue-Bold";
  font-size: 27px;
  color: #fff;
  text-align: center;
  margin-bottom: 5px;
}

.box_title_blu {
  border-width: 0px;
  width: 405px;
  height: 30px;
  display: flex;
  font-family: "GucciSans-Bold", "Gucci Sans Bold", "Gucci Sans Book", "Gucci Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #fff;
  text-align: left;
  line-height: 18px;
  text-transform: none;
  margin-top: 15px;
  text-align: justify;
}



.box_little_title {
  text-transform: uppercase;
  margin: 0 10px;
  font-family: "GucciSans-Book";
  font-size: 12px;
  color: #fff;
}

.box_little_title_2 {
  margin: 0 10px;
  font-family: "GucciSans-Bold";
  font-size: 14px;
  color: #fff;
}

.people_circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #7fb8dd;
  outline-color: #00a4e2;
  margin-left: -3px;
  outline-width: 3px;
  outline-style: solid;
}

.people_circle_user {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  outline-color: #00a4e2;
  margin-left: -3px;
  outline-width: 3px;
  outline-style: solid;
}

.people_circle_text {
  color: #00a4e2;
  font-family: 'GucciSans-Bold';
  margin: 7px;
}

.progress {
  --bs-progress-bg: #7fb8dd !important;
  /*
  --bs-progress-bg: #8eb147 !important;
  */
  --bs-progress-bar-color: #fff !important;
  --bs-progress-bar-bg: #fff !important;
  --bs-progress-height: 0.3rem !important;
}

.progress_bar_balloon {
  position: relative;
  margin: 0px 10px 0px 10px;
}

.progress_label_balloon {
  position: absolute;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-family: 'GucciSans-Bold';
  width: 85px;
}

.balloon_label_text {
  text-align: -webkit-center;
  margin-top: 5px;
  color: #fff;
  font-family: 'GucciSans-Book';
}

.balloon_label_text_l {
  text-align: -webkit-center;
  margin-top: 5px;
  width: 250px;
}

.box_text {
  text-align: left;
  /* margin-top: 10px; */
  font-size: 16px;
  font-family: "GucciSans-Book";
  color: #fff;
  max-width: 350px;
}

.box_text_ngo_challenge {
  margin-top: 5px;
  font-size: 13px;
  font-family: "GucciSans-Book";
  text-align: justify;
  width: 95%;
}

.box_subtext_ngo_challenge{
    font-style: italic;
    font-size: 11px;
    position: absolute;
    bottom: 10px;
    left: 20px;

}

.box_text_enter_the_bycicle {
  margin-top: 0px;
  font-size: 14px;
  font-family: "GucciSans-Book";
  text-align: justify;
  width: 95%;
}

/*
.box_text_ngo_challenge::-webkit-scrollbar {
  display: none;
}*/

::-webkit-scrollbar {
  width: 6px;
  background: #b8b8b8;
  border-radius: 32px;
}

::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 32px;
}


.leaderboard_see_all_cont::-webkit-scrollbar {
  width: 5px;
  background: #b8b8b8;
  border-radius: 32px;
}

.leaderboard_see_all_cont::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 32px;
}

.box_text_ngo_challenge::-webkit-scrollbar {
  width: 5px;
  background: #b8b8b8;
  border-radius: 32px;
}

.box_text_ngo_challenge::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 32px;
}

.box_text_blu {
  /* text-align: left; */
  font-size: 14px;
  color: #000000;
  font-family: "GucciSans-Book";
  align-self: center;
  text-align: justify;
  border-radius: 5px;
}

.box_icon_blu{
  background-color: #00a4e2;
  width: 35px;
  height: 35px;
  min-width: 35px;
  padding: 5px;
  border-radius: 15px;
  margin-right: 20px;
  text-align: -webkit-center;
  align-content: center;
}


.text_container {
  margin-bottom: 15px;
  position: relative;
  align-items: center;
}

.user_name_white {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-family: "GucciSans-Bold";
}

.number_circle {
  height: 30px;
  width: 30px;
  min-width: 30px;
  background-color: #fff;
  color: #00a4e2;
  font-family: "GucciSans-Bold";
  border-radius: 20px;
  text-align: center;
  line-height: 2;
  margin-right: 10px;
}

.unhcr_logo_small_container {
  text-align: -webkit-center;
  /*margin-top: 10px;*/
  height: 60px;
}

#unhcr_logo_small {
  /*background-image: url("./resources/img/logo_unhcr_blu.png");*/
  background-image: url("./resources/img/logo-unicef-no\ back.png");
  height: 60px;
  width: 200px;
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
}

#u266_small_container {
  background-image: url("./resources/img/u266.png");
  width: 300px;
  height: 190px;
  background-size: 250px;
  background-repeat: no-repeat;
  background-position: center;
}

#u266_claim {
  font-size: 30px;
  font-family: 'BebasNeue-Bold';
  align-self: center;
  text-align: -webkit-center;
  color: #fff;
  margin-top: 30px;
}

.info_ico {
  align-self: center;
  position: absolute;
  right: 10px;
}

.join_link {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
  align-content: center;
  margin-right: 15px;
  font-size: 16px;
  font-family: 'GucciSans-Bold';
}

.team_cont {
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: -10px;
}

.team_num {
  font-size: 16px;
  align-content: center;
  font-family: 'GucciSans-Bold';
  min-width: 30px;
}

#join_challenge_button {
  background-color: #fff;
  color: #00a4e2;
  font-family: "BebasNeue-Bold";
  font-size: 26px;
  cursor: pointer;
  margin-top: 60px;
  padding: 10px 30px;
  text-align: -webkit-center;
  margin-bottom: 10px;
  border-radius: 5px;
}

#join_challenge_button_ko {
  background-color:#fff;
  border-radius: 20px;
  color: #00a4e2;
  font-family: "BebasNeue-Bold";
  font-size: 26px;
  cursor: pointer;
  margin-top: 50px;
  padding: 10px 30px;
  text-align: -webkit-center;
  margin-bottom: 10px;
}

#join_challenge_button_ja {
  background-color: #fff;
  border-radius: 5px;
  color: #00a4e2;
  font-family: "BebasNeue-Bold";
  font-size: 26px;
  cursor: pointer;
  margin-top: 30px;
  padding: 10px 30px;
  text-align: -webkit-center;
  margin-bottom: 10px;
}

.joined_challenge_button {
  background-color: rgb(255, 255, 255);
  /* width: 210px; */
  color: #00a4e2;
  border-radius: 5px;
  font-family: "BebasNeue-Bold";
  font-size: 25px;
  cursor: pointer;
  margin-top: 8px;
  padding: 4px 35px;
  text-align: -webkit-center;
  /* margin: 18px auto; */
  margin: 16px auto 10px;
  max-width: 200px;
}

.joined_challenge_button_ko {
  background-color: rgb(255, 255, 255);
  /* width: 210px; */
  border-radius: 5px;
  color: #00a4e2;
  font-family: "BebasNeue-Bold";
  font-size: 18px;
  cursor: pointer;
  margin-top: 8px;
  padding: 4px 35px;
  text-align: -webkit-center;
  /* margin: 18px auto; */
  margin: 16px auto 10px;
  max-width: 200px;
}

.joined_challenge_button_ja {
  background-color: rgb(255, 255, 255);
  /* width: 210px; */
  border-radius: 5px;
  color: #00a4e2;
  font-family: "BebasNeue-Bold";
  font-size: 18px;
  cursor: pointer;
  margin-top: 8px;
  padding: 4px 35px;
  text-align: -webkit-center;
  /* margin: 18px auto; */
  margin: 10px 5px 10px;
  max-width: 200px;
}

.joined_challenge_button_pt {
  background-color: rgb(255, 255, 255);
  /* width: 210px; */
  border-radius: 5px;
  color: #00a4e2;
  font-family: "BebasNeue-Bold";
  font-size: 21px;
  cursor: pointer;
  margin-top: 8px;
  padding: 4px 30px;
  text-align: -webkit-center;
  /* margin: 18px auto; */
  margin: 10px 5px 10px;
  max-width: 200px;
}

.team_button {
  background-color: rgb(0, 0, 0);
  /* width: 210px; */
  border-radius: 5px;
  color: #ffffff;
  font-family: "BebasNeue-Bold";
  font-size: 25px;
  cursor: pointer;
  margin-top: 8px;
  padding: 4px 35px;
  text-align: -webkit-center;
  /* margin: 18px auto; */
  margin: 16px auto 10px;
  max-width: 200px;
}

.pb_pd {
  padding-top: 20px;
  padding-bottom: 20px;
}

.column {
  margin-left: 10px;
}


.left_box_line_publish {
  width: 210px;
  align-items: center;
  /*
  position: relative;
  left: 200px;
  */
}

.left_box_line_container {
  align-items: center;
  margin: 10px;
}

.under_progressbar {
  /*width: 100%;*/
  margin-top: 15px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.lines_container {
  margin: 0 auto;
}

.left_box {
  background-color: #000000;
  /*height: 75px;*/
  height: 55px;
  /*  width: 75px;*/
  width: 55px;
  border-radius: 20px;
  margin-right: 10px;
}

.left_box_text {
  color: #f2d474;
  font-size: 24px;
  text-align: -webkit-center;
  margin: 16px 10px;
  font-family: 'BebasNeue-Bold';
  /*line-height: 20px;*/
  line-height: 26px;
}

.dotted_line {
  border-bottom: dotted 1px;
  border-color: white;
}

#u280 {
  /*  background-image: url("./resources/img/image007.png");*/
  background-image: url("./resources/img/unicef_1.jpg");
  width: 440px;
  height: 230px;
  background-size: 445px;
  background-repeat: no-repeat;
  background-position: 0 -25px;
  /* padding: 10px; */
  margin-top: -10px;
  margin-left: -10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  }

.photo_credits{
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: white;
    font-style: italic;
    font-size: 11px;
    font-family: "GucciSans-Book";
    text-shadow: 1px 1px 3px black;
}

.counter_hours {
  background-color: #ffffff;
  opacity: 0.5;
  width: max-content;
  padding: 5px;
  border-radius: 8px;
}

.clock_icon {
  padding: 2px 4px 0px 4px;
}

.time {
  font-size: 12px;
  align-self: center;
}

.timer {
  background-color: rgba(255, 255, 255, 0.4980392156862745);
  width: max-content;
  padding: 1px 5px 1px 5px;
  border-radius: 8px;
  color: white;
  display: -webkit-flex;
  margin: 4px;
}

.leaderboard_container {
  width: 420px;
  background-color: white;
  height: 415px;
  padding: 10px;
  position: relative;
    box-shadow: 0 3px 6px #bfbfbf;
  border-radius: 5px;
}

.team_container {
  background-color: white;
  width: 420px;
  height: 415px;
  padding: 10px;
  position: relative;
    box-shadow: 0 3px 6px #bfbfbf;
  border-radius: 5px;
}

.box_title_container {
  display: flex
}

.filters {
  display: -webkit-flex;
  width: 420px;
  margin-bottom: 10px;
}

.see_button {
  border-width: 0px;
  /*width: 70px;*/
  height: 20px;
  background: inherit;
  background-color: rgba(0, 0, 0, 1);
  border: none;
  border-radius: 30px;
  box-shadow: none;
  font-family: "GucciSans-Bold", "Gucci Sans Bold", "Gucci Sans Book", "Gucci Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 18px;
  text-align: -webkit-center;
  padding-top: 2px;
  margin-top: 20px;
  width: fit-content;
  padding: 0px 47px;
  margin-left: 10px;
}

.switch_text {
  color: white;
  font-family: "GucciSans-Bold", "Gucci Sans Bold", "Gucci Sans Book", "Gucci Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 9px;
  padding: 5px 0px 5px 14px;
}

.checkbox_button {
  margin-right: 20px;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox:checked+.label-reversible .ball-reversible {
  transform: translateX(19px);
}

.label {
  background-color: #111;
  border-radius: 50px;
  cursor: pointer;
  display: -webkit-flex;
  align-items: center;
  -webkit-justify-content: space-between;
  padding: 5px;
  position: relative;
  height: 7px;
  width: 80px;
  transform: scale(1.5);
  margin-left: 15px;
  margin-right: 5px;
}

.label .ball {
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 13px;
  width: 13px;
  transform: translateX(0px);
  transition: transform 0.2s linear;
}

.checkbox:checked+.label .ball {
  transform: translateX(73px);
}

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  33% {
    width: .2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  100% {
    width: .2em;
    height: .5em;
    border-color: #212121;
    transform: translate3d(0, -.5em, 0) rotate(45deg);
  }
}

#isAcceptedCheckbox {
  width: 18px;
  height: 18px;
}

#isAcceptedCheckbox:checked {
  accent-color: #00a4e2;
}

.terms_link {
  color: #000 !important;
}

.terms_error {
  color: #e60c1a;
  margin-top: 6px;
}

.selected_button {
  height: 20px;
  width: auto;
  padding: 2px 8px;
  /* padding-right: 8px; */
  background: inherit;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 30px;
  box-shadow: none;
  font-family: "GucciSans-Bold", "Gucci Sans Bold", "Gucci Sans Book", "Gucci Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 18px;
  text-align: -webkit-center;
  /* padding-top: 4px; */
  margin-top: -4px;
  border: 1px solid white;
  margin-right: 5px;
  cursor: pointer;
  min-inline-size: -moz-fit-content;
  min-inline-size: fit-content;
}

.unselected_button {
  border: 1px solid rgb(0, 0, 0);
  height: 20px;
  width: auto;
  padding: 2px 8px;
  background: inherit;
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "GucciSans-Bold", "Gucci Sans Bold", "Gucci Sans Book", "Gucci Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  color: #000000;
  line-height: 18px;
  text-align: -webkit-center;
  /*padding-top: 4px;*/
  margin-top: -4px;
  margin-right: 5px;
  cursor: pointer;
  min-inline-size: -moz-fit-content;
  min-inline-size: fit-content;
}


.waiting_steps_img {
  width: 350px;
  height: 300px;
  margin-left: 50px;
  /*margin-top: 40px;*/
  background-image: url("./resources/img/first_steps.png");
  background-size: 350px;
  background-repeat: no-repeat;
  background-position: center;
}

.user_container {
  /* width: 420px;*/
  max-height: 260px;
  padding-right: 7px;
}

.my_team {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 20px 0;
}

.my_team::-webkit-scrollbar {
  width: 5px;
  background: #b8b8b8;
  border-radius: 32px;
}

.my_team::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 32px;
}

.recently_joined {
  overflow-y: auto;
  overflow-x: hidden;

  /*
  height: 315px;
  max-height: 230px;*/
}

.recently_joined::-webkit-scrollbar {
  width: 5px;
  background: #b8b8b8;
  border-radius: 32px;
}

.recently_joined::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 32px;
}

.leaderboard_users {
  margin-top: 15px;
  overflow-y: auto;
  /* height: 670px;*/
  height: 315px;
  max-height: 310px;
  padding-right: 7px;
}

/*
.leaderboard_users::-webkit-scrollbar {
  display: none;
}
.leaderboard_users:hover::-webkit-scrollbar {
  width: 8px;
  background: #b8b8b8;
  border-radius: 32px;
}*/
.leaderboard_users::-webkit-scrollbar {
  width: 5px;
  background: #b8b8b8;
  border-radius: 32px;
}

.leaderboard_users::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 32px;
}

.modal_content_container>nav {
  margin-left: -15px !important;
}

.user_row {
  display: -webkit-flex;
  align-items: center;
  margin-left: 2px;
  /*margin-bottom: 2px;*/
}

.user_row_width {
  width: 100%;
}

.name_cont {
  width: 275px;
  /* padding-top: 7px;*/
}

.name_cont_modal {

  width: 300px;

}

.user_rank {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 50%;
    background-color: black;
    position: relative;
    margin-right: 20px;
    /* margin-bottom: 5px; */
    align-self: center;
}

.user_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: black;
  position: relative;
  margin-right: 20px;
  /*margin-bottom: 5px;*/
}

.user_img_present {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  margin-right: 20px;
  background-color: #f9f4e5;
}

.user_rank_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f2f2f2;
  position: relative;
  margin-right: 20px;
  margin-top: 5px;
  background-size: cover;
  background-repeat: no-repeat;

}

.user_nav_ico {
  position: absolute;
  right: 5px;
}

.user_nav_img {
  width: 34px;
  height: 33px;
  border-radius: 50%;
  background-color: #ffffff;
  position: relative;
  margin-right: 20px;
  margin-bottom: 3px;
  margin-top: -5px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.user_rank_index {
  font-size: 13px;
  font-family: 'GucciSans-Bold';
  color: white;
  text-align-last: center;
  padding-top: 2px;
}

.user_rank_text {
  font-size: 20px;
  font-family: 'GucciSans-Bold';
  color: rgb(0, 0, 0);
  text-align-last: center;
  margin-top: 4px;
  letter-spacing: -1px;
  text-transform: uppercase;
}

.user_nav_text {
  font-size: 18px;
  font-family: 'GucciSans-Bold';
  color: rgb(0, 0, 0);
  text-align-last: center;
  /*margin-top: 4px;*/
  letter-spacing: -1px;
  text-transform: uppercase;
  padding-top: 3px;
  margin-left: -1px;
}

.user_text {
  font-size: 20px;
  font-family: 'GucciSans-Bold';
  color: rgb(255, 255, 255);
  text-align-last: center;
  margin-top: 4px;
  letter-spacing: -1px;
}

.user_name {
  font-family: 'GucciSans-Bold';
  margin-right: 5px;
  align-self: center;
  font-size: 16px;
  line-height: 26px;
  padding-top: 2px;
}

.user_surname {
  text-transform: uppercase;
  font-family: 'GucciSans-Bold';
  align-self: center;
}

.location {
  text-transform: uppercase;
  font-family: 'GucciSans-Book';
  padding: 0;
  margin-top: -3px;
  font-size: 11px;
  color: #8c8a8c;
}

.coins_label {
  text-transform: uppercase;
  font-family: 'GucciSans-Book';
  padding: 0;
  margin-top: -3px;
  font-size: 11px;
  color: #000000;
}

.coins {
  position: relative;
  margin-left: 5px;
  font-family: 'GucciSans-Bold';
  margin-right: 2px;
}

.last_user {
  display: -webkit-flex;
  /*position: absolute;*/
  bottom: 0;
  width: 420px;
  border-top: dotted;
  padding-top: 20px;
  padding-bottom: 20px;
  background: white;
  border-bottom-right-radius: 15px;
}

.user_list_img {
  width: 315px;
  padding-bottom: 20px;
}

.user_info {
  display: -webkit-flex;
  /* padding-top: 4px;*/
}

.user_access {
  text-transform: uppercase;
  font-size: 10px;
  font-family: 'GucciSans-Book';
  margin-top: -4px;
}

.team_box {
  width: 420px;
  height: 300px;
  max-height: 300px;
}

.leaderboard_see_all_cont {
  margin-top: 15px;
  overflow: auto;
  /*height: 450px;*/
  /* height: 400px;*/
}

.leaderboard_see_all_team {
  margin-top: 15px;
  overflow: auto;
  height: 400px;
}

.leaderboard_see_all_team_cont {
  margin-top: 15px;
  overflow: auto;
  height: 250px;
}


/* COUNTDOWN */
.cd>div {
  place-content: center;
  margin-bottom: 50px;
}

.countdown_container {
  color: #000;
  width: 40%;
  margin-top: 9px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.countdown_container_menu {
  color: #000;
  width: 96%;
  margin-top: 9px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.countdown_container_leftbar {
  width: 100%;
}

.countdown_container_string {
  padding-top: 66px;
}

.countdown_container_string_leftbar {
  margin-top: -37%;
  padding-bottom: 25%;
}

.counter_string {
  width: 80%;
}

.countdown_columns {
  font-family: 'GucciSans-Bold';
  margin-left: 25px;
  margin-right: 25px;
}

.countdown_counter_text {
  font-size: 35px;
  cursor: pointer;
}

.countdown_text {
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: -5px;
}

.loader-cont-popup {
  position: absolute;
  left: 35%;
  top: 35%;
}

.loader-cont {
  text-align: -webkit-center;
  margin-top: 20px;
}

.loader-cont-recently {
  text-align: -webkit-center;
  margin-top: 20px;
  height: 80px;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid rgb(5, 5, 5);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.css-26l3qy-menu {
  z-index: 4 !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* activity modal */
.strava_button {
  text-align: -webkit-center;
  width: 350px;
  height: 25px;
  background: black;
  color: white;
  font-family: 'GucciSans-Book';
  padding: 3px;
  border-radius: 40px;
  position: relative;
  margin-left: calc(50% - 175px);
  margin-top: 10px;
  cursor: pointer;
}

.left_modal {
  width: 50%;
  height: 350px;
  margin-top: 50px;
}

.right_modal {
  width: 50%;
  height: 123px;
  align-self: center;
  padding-left: 30px;
}

.activity_label {
  font-family: 'GucciSans-Bold';
  margin-top: 10px;
  margin-bottom: 15px;
}

.activity_date_input {
  width: 97%;
}

.select_activity {
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid;
  margin-bottom: 10px;
  margin-right: 30px;
  cursor: pointer;
  color: #000;
}

.date_picker_activity {
  width: 386px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid;
  margin-bottom: 10px;
  margin-right: 30px;
  cursor: pointer;
  color: #000;
}

.css-tj5bde-Svg {
  fill: black !important;
}

.css-1okebmr-indicatorSeparator {
  visibility: hidden;
}

.css-14el2xx-placeholder {
  color: black !important;
}

.input_activity {
  /*width: 387px;*/
  width: 100%;
  height: 38px;
  border: none;
  border-bottom: 1px solid;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: 'GucciSans-Book';
}

.input_search_team_user {
  /*width: 387px;*/
  width: 100%;
  height: 38px;
  border: none;
  border-bottom: 1px solid;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: 'GucciSans-Book';
}

.activity_date_icon {
  position: relative;
  margin-top: -90px;
  margin-left: 370px;
  z-index: 3;
}

.datepicker_modal {
  padding-left: 10px;
}

.add_activity_measurement_container {
  display: flex;
}

.cancel_button {
  text-align: -webkit-center;
  width: 203px;
  height: 25px;
  background: white;
  color: black;
  font-family: 'GucciSans-Book';
  padding: 5px;
  border-radius: 40px;
  border: 1px solid;
  position: absolute;
  bottom: 30px;
  cursor: pointer;
}

.cancel_create_team_button {
  text-align: -webkit-center;
  width: 203px;
  height: 25px;
  background: white;
  color: black;
  font-family: 'GucciSans-Book';
  padding: 5px;
  border-radius: 40px;
  border: 1px solid;
  position: relative;
  cursor: pointer;
  margin: auto;
}


.confirm_add_activity_button {
  text-align: -webkit-center;
  width: 203px;
  height: 25px;
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-family: 'GucciSans-Book';
  padding: 5px;
  border-radius: 40px;
  border: 1px solid;
  position: absolute;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
}

.confirm_create_team_button {
  text-align: -webkit-center;
  width: 203px;
  height: 25px;
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-family: 'GucciSans-Book';
  padding: 5px;
  border-radius: 40px;
  border: 1px solid;
  position: relative;
  cursor: pointer;
  margin: auto;
}

.confirm_delete_activity_button {
  text-align: -webkit-center;
  width: auto;
  height: 25px;
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-family: 'GucciSans-Bold';
  padding: 5px 25px;
  border-radius: 40px;
  border: 1px solid;
  position: absolute;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
}

.cancel_delete_button {
  text-align: -webkit-center;
  width: auto;
  height: 25px;
  background: white;
  color: black;
  font-family: 'GucciSans-Book';
  padding: 5px 25px;
  border-radius: 40px;
  border: 1px solid;
  position: absolute;
  bottom: 30px;
  cursor: pointer;
}

input[type=radio] {
  margin-right: 10px;
  cursor: pointer;
  width: 18px;
  height: 18px;
  align-self: center;
}

/* join challenge modal */

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 6
}

.activity_modal {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 30px;
  outline: none;
  padding: 30px;
  width: auto !important;
  height: 550px !important;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}


.activity_form_container {
  height: auto;
}

.modal_popup {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 30px;
  outline: none;
  padding: 30px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.modal_base_languages {
  max-width: 420px;
  width: auto !important;
  max-height: 400px !important;
  height: 61vh !important;
  min-width: 320px;
}

.modal_base {
  max-width: 420px;
  width: auto !important;
  max-height: 550px !important;
  height: auto !important;
  min-height: 400px;
}

.modal_base_delete {
  max-width: 420px;
  width: auto !important;
  max-height: 550px !important;
  height: auto !important;
  min-width: 350px;
  min-height: 280px;
}

.modal_base_my_tracking {
  width: 365px !important;
  max-width: 420px;
  max-height: 550px !important;
  height: auto !important;
  min-height: 400px;
}

.modal_base_team {
  width: 420px !important;
  max-width: 420px;
  max-height: 600px !important;
  height: auto !important;
  min-height: 400px;
}

.modal_base_info_team {
  width: 420px !important;
  max-width: 420px;
  max-height: 540px !important;
  min-height: 400px;
}

.modal_base_my_team {
  width: 420px !important;
  max-width: 420px;
  max-height: 600px !important;
  height: auto !important;
  min-height: 400px;
}

.modal_base_create {
  min-width: 300px;
  width: auto !important;
  max-width: 40%;
  max-height: 600px !important;
  height: auto !important;
  min-height: 40vh;
  min-width: 400px;
}

.modal_base_team_info {
  min-width: 300px;
  width: auto !important;
  min-width: 30%;
  max-width: 40%;
  max-height: 600px !important;
  height: auto !important;
  margin-bottom: 50px;
}

.confirm_delete {
  height: auto !important;
  width: auto !important;
}

.modal_header {
  place-items: center;
}

.modal_content_title {
  margin-right: auto;
  font-family: "GucciSans-Bold";
  font-size: 18px;
}

.modal_content_subtitle {
  font-family: "GucciSans-Bold";
  font-size: 16px;
  margin-top: 20px;
}

.modal_content_subtitle_text {
  font-family: "GucciSans-Book";
  font-size: 15px;
  margin-top: 15px;
}

.modal_content_subtitle_text_info {
  font-family: "GucciSans-Book";
  font-size: 16px;
  margin-top: 15px;
}


.close_button {
  background-color: black !important;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.modal_content_container {
  width: 100%;
}

.modal_card_img_container {
  height: 320px;
}

.modal_img {
  width: 433px;
}

.modal_card {
  text-align-last: center;
  margin: auto;
  text-align: -webkit-center;
}

.modal_title {
  font-family: "GucciSans-Bold", "Gucci Sans Bold", "Gucci Sans Book", "Gucci Sans", sans-serif;
  font-size: 20px;
  text-align: left;
  line-height: 18px;
  text-transform: none;
  margin: 15px;
}

.modal_subtitle {
  font-family: "GucciSans-Book";
  font-size: 16px;
  text-align: left;
  line-height: 18px;
  text-transform: none;
  margin: 15px;
}

.modal_button {
  background-color: black;
  width: 250px;
  border-radius: 20px;
  color: white;
  font-family: "GucciSans-Bold";
  cursor: pointer;
  margin-top: 20px;
  padding: 10px;
  text-align: -webkit-center;
  margin-bottom: 10px;
}


/* tracking modal */


.modal_activity_list {
  overflow-y: auto;
  overflow-x: hidden;
  height: 450px;
  padding-right: 5px;
}

.modal_activity_list::-webkit-scrollbar {
  width: 5px;
  background: #b8b8b8;
  border-radius: 32px;
}

.modal_activity_list::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 32px;
}


.activity_line {
  margin: 10px 0;
  align-items: center;
}

.activity_icon_image_present {
  width: 30px;
  height: 30px;
  background-size: 30px;
  margin-right: 10px;
  padding: 10px;
  background-repeat: no-repeat;
  background-position: center;
  align-self: center;
}

.activity_icon_image {
  background-color: #000;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  margin-right: 10px;
  font-family: "GucciSans-Bold";
  font-size: 22px;
  color: white;
  text-align-last: center;
  padding: 10px;
}

.activity_list_text_container {
  width: 270px;
}

.delete_activity {
  margin-bottom: 5px;
}

.activity_list_text {
  font-family: "GucciSans-Book";
  font-size: 16px;
}

.activity_line_right_side {
  margin-left: auto;
  align-items: center;
}

.not_active_flag {
  opacity: 0.3;
  margin: 0 5px;
}

.active_flag {
  margin: 0 5px;
}

/* LANGUAGES */
.cancel_button_s {
  text-align: -webkit-center;
  width: 100px;
  height: 25px;
  background: white;
  color: black;
  font-family: 'GucciSans-Book';
  padding: 5px;
  border-radius: 40px;
  border: 1px solid;
  position: absolute;
  bottom: 30px;
  cursor: pointer;
  margin-top: 15px;
}

.confirm_button_s {
  text-align: -webkit-center;
  width: 100px;
  height: 25px;
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-family: 'GucciSans-Book';
  padding: 5px;
  border-radius: 40px;
  border: 1px solid;
  position: absolute;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  margin-top: 15px;
}

.flag {
  width: 40px;
  height: 25px;
  background-size: cover;
  border-radius: 5px;
  background-repeat: no-repeat;
  margin: 2px 5px 2px 5px;
  background-position: center;
  align-self: center;
}

.language_row {
  margin-top: 15px;
}

.languages_cont {
  margin-top: 20px;
  font-size: 15px;
}


/* joined */

#workplace_logo {
  /*background-image: url("./resources/img/Workplace.svg");*/
  background-image: url("./resources/img/Workplace-svg_unicef.svg");
  width: 30px;
  height: 30px;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
}

#workplace_logo_ja {
  background-image: url("./resources/img/Workplace-svg_unicef.svg");
  width: 30px;
  height: 30px;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
}

#workplace_logo_pt {
  background-image: url("./resources/img/Workplace-svg_unicef.svg");
  width: 30px;
  height: 30px;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
}

#coin_container {
  background-image: url("./resources/img/coin.png");
  /* 
  background-image: url("./resources/img/black_coin.png");
  */
  width: 130px;
  height: 125px;
  background-size: 130px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
}

#balance {
  width: 200px;
  outline: 3px solid;
  outline-color: rgb(255, 255, 255);
  font-family: 'BebasNeue-Bold';
  font-size: 30px;
  color: rgb(255, 255, 255);
  place-content: center;
  margin-top: 20px;
  padding: 0px 30px;
  border-radius: 5px;
}

#balance_ko {
  width: 200px;
  outline: 3px solid;
  outline-color: rgb(255, 255, 255);
  font-family: 'BebasNeue-Bold';
  font-size: 30px;
  color: rgb(255, 255, 255);
  place-content: center;
  margin-top: 15px;
  padding: 3px 30px;
  line-height: 30px;
  border-radius: 5px;
}

#info_popup_icon {
  margin-left: 10px;
  margin-top: 15px;
}

#info_popup_icon_team {
  position: absolute;
  right: 20px;
  top: 20px;
}

.info_popup_container {
  margin: 20px 0;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
  font-family: 'GucciSans-Book';
}


.info_popup_container::-webkit-scrollbar {
  width: 5px;
  background: #b8b8b8;
  border-radius: 32px;
  margin-left: 5px;
}

.info_popup_container::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 32px;
  margin-left: 5px;
}

.info_popup_table {
  border: 1px solid #000;
  width: 100%;
  border-radius: 5px !important;
}

.info_popup_table_header {
  font-family: "GucciSans-Bold";
  border: 1px solid #000;
  padding: 5px 10px;
}

.info_popup_table_row {
  font-family: "GucciSans-Book";
  border: 1px solid #000;
}

.info_popup_table_cell {
  font-family: "GucciSans-Book";
  border: 1px solid #000;
  padding: 5px 10px;
}

.info_popup_table_cell_image {
  display: flex;
  align-items: center;
}

.activity_image_container {
  width: 30px;
  height: 30px;
  background-size: 30px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
}

#my_coins {
  font-family: "BebasNeue-Bold";
  font-size: 70px;
  color: rgb(255, 255, 255);
  margin-right: 10px;
}

#coins_earned {
  font-family: "BebasNeue-Bold";
  font-size: 26px;
  color: rgb(255, 255, 255);
  line-height: 28px;
  text-align: left;
}

.joined_box_blu {
  background-color: #7fb8dd;
  width: 400px;
  height: 150px;
  padding: 10px;
  margin: 30px 10px;
  text-align: -webkit-center;
  font-family: 'GucciSans-Book';
  color: white;
  font-size: 14px;
}

.joined_box_blu_text {
  line-height: 16px;
  margin: 10px;
  font-size: 14px;
}

.joined_box_blu_button {
  background-color: white;
  width: fit-content;
  padding: 2px 25px;
  font-family: "GucciSans-Book";
  color: #00a4e2;
  font-size: 12px;
  border-radius: 20px;
}

.bi-gift-fill {
  margin: 10px
}

.bi-lock-fill {
  margin: 10px
}

.dashed_line {
  border-bottom: dashed 1px;
  border-color: white;
}

#publish_text {
  font-family: "GucciSans-Book";
  font-size: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: justify;
}

.left_box_line_text {
  font-family: "GucciSans-Book";
  font-size: 16px;
  color: rgb(0, 0, 0);
  text-align: center;
  margin: 20px 10px;
}

.left_box_line_button {
  font-family: "BebasNeue-Bold";
  font-size: 26px;
  color: #00a4e2;
  background-color: rgb(0, 0, 0);
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 50px;
  align-self: center;
  margin: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

.left_box_line_button_ja {
  font-family: "BebasNeue-Bold";
  font-size: 26px;
  color: #00a4e2;
  background-color: rgb(0, 0, 0);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  padding: 5px 30px;
  align-self: center;
  margin: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.button_retry {
  background-color: rgb(0, 0, 0);
  /* width: 210px; */
  border-radius: 5px;
  color: #fff;
  font-family: "BebasNeue-Bold";
  font-size: 26px;
  cursor: pointer;
  margin-top: 8px;
  padding: 4px 35px;
  text-align: -webkit-center;
  /* margin: 18px auto; */
  margin-top: 420px;
  margin-left: 50px;
  margin-bottom: 5px;
  z-index: 9;
  position: relative
}

.button_stamp {
  background-color: rgb(0, 0, 0);
  /* width: 210px; */
  border-radius: 5px;
  color: #00a4e2;
  font-family: "BebasNeue-Bold";
  font-size: 26px;
  cursor: pointer;
  margin-top: 8px;
  padding: 4px 35px;
  text-align: -webkit-center;
  /* margin: 18px auto; */
  margin-top: 420px;
  margin-left: 40px;
  margin-bottom: 5px;
  z-index: 9;
  position: relative
}

.link_share {
  font-family: "GucciSans-Bold";
  font-size: 17px;
  color: black;
  margin-top: 4px;
  cursor: pointer;
  position: absolute;
}

.photo_image {
  height: 400px;
  max-width: 400px;
  max-height: 400px;
  background-size: cover;
  width: 400px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}

#mask {
  background-image: url('./resources/img/mask2.png');
  width: 400px;
  height: 400px;
  position: relative;
  z-index: 2;
  background-size: contain;
  margin-top: -400px;
}

/*
.mask_container {
  width: 400px;
  height: 400px;
  position: absolute;
  left: 80px;
  z-index: 2;
  top: 85px;
}*/
.mask_container {
  width: 530px;
  height: 400px;
  position: absolute;
  left: 85px;
  z-index: 2;
  top: 115px;
}

.white_bg {
  width: 125px;
  height: 400px;
  position: relative;
  z-index: 2;
  background-size: contain;
  background: white;
  margin-top: -400px;
}

.react-html5-camera-photo {
  position: relative !important;
  width: 400px !important;
  height: 400px !important;
  left: 0px;
  z-index: 2;
  top: 0px;
}

.react-html5-camera-photo>img,
.react-html5-camera-photo>video {
  width: 490px !important;
  height: 400px !important;
}

#container-circles {
  bottom: 10px !important;
}

#outer-circle {
  background-color: hsl(0deg 0% 0% / 32%) !important;
  top: 20px;
  left: -40px !important;
  cursor: pointer;
}

.photo_popup {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 30px;
  outline: none;
  padding: 30px;
  width: 500px !important;
  height: 565px !important;

  margin-right: -50%;
  transform: translate(-50%, -50%);
}

/* PRIVACY POLICY */


.ppolicy_global_container {
  width: calc(100% - 20px);
  padding: 30px;
}

.ppolicy_container {
  align-items: center;
  cursor: pointer;
}

.back_button_ppolicy {
  font-family: "BebasNeue-Bold";
  font-size: 30px;
  margin-left: 5px;
  color: #000;
  text-align: -webkit-center;
}

.ppolicy_title {
  font-family: "BebasNeue-Bold";
  font-size: 40px;
}


.ppolicy_text {
  width: calc(60% - 20px);
  text-align: justify;
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.ppolicy_text::-webkit-scrollbar {
  width: 5px;
  background: #b8b8b8;
  border-radius: 32px;
  margin-left: 10px;
}

.ppolicy_text::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 32px;
  margin-left: 5px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@media (max-width: 1900px) {
  .login_main_title {
    font-size: 80px;
  }


  
.title_container {
    /* margin-top: 50px; */
    margin-top: 40px;
    width: 90%;
}

}

@media (max-width: 1600px) {

  /* LOGIN */

  .image1 {
    width: 300px;
    height: 175px;
  }

  .image2 {
    width: 200px;
    height: 200px;
    margin-right: -115px;
  }

  .image3 {
    width: 250px;
    height: 175px;
  }

  .image4 {
    width: 250px;
    height: 190px;
  }


  .u424_container {
    height: 350px;
    width: 550px;
    background-size: 550px;
    position: absolute;
    bottom: 50px;
    right: 0px;
  }

  .login_subtitle {
    margin-top: 20px;
  }

  .login_main_title {
    font-size: 70px;
    line-height: 70px;
  }


  .logos_container {
    margin: 20px 0;
  }

  .gucci_logo_container {
    width: 180px;
    background-size: 180px;
  }

  .logo_separator {
    font-size: 50px;
    margin-top: 0px;
  }

  .unhcr_logo_container {
    height: 75px;
    width: 230px;
    background-size: 230px;
  }

  .title_container {
    /*margin-top: 30px;*/
  }

}

@media (max-width: 1385px) {
  .team_container {
    /*    margin-bottom: 80px;*/
  }

  .mobile_team_container {
    /*margin-top: 10px;*/
  }

  .leaderboard_container {
    margin-bottom: 80px;
  }
}

@media (max-width: 1200px) {
  /* LOGIN */

  .main_title_container {
    width: 60%;
  }

  .u424_container {
    height: 220px;
    width: 430px;
    background-size: 377px;
    position: absolute;
    bottom: 50px;
    right: 30px;
  }

  .login_title {
    font-size: 14px;
  }

  .title_container {
    width: 85%;
  }


  .add_activity_measurement_container {
    display: block;
  }

}

@media (max-width: 1100px) {


  .image1 {
    width: 200px;
    height: 125px;
  }

  .image2 {
    width: 145px;
    height: 140px;
  }

  .image3 {
    width: 170px;
    height: 120px;
    margin-top: -42px;
  }

  .image4 {
    width: 184px;
    height: 142px;
    margin-top: -52px;
  }
}

@media (max-width: 990px) {
  .login_info_container{
    height: auto;
  }

  .logos_container_mobile{
    display: block;
  }

  .gucci_logo_container{
    margin: 20px auto;
  }

    .login_container {
    width: calc(100% - 30px);
    height: -webkit-fill-available;
    position: absolute;
    top: 50px;
    text-align: -webkit-center;
    border: 15px solid #00a4e2;
    display: grid;
    /* position: fixed; */
    /* align-content: center; */
    overflow-y: auto;
    height: calc(100vh - 80px);
    overflow-x: hidden;
  }

  .left_image {
    width: 100%;
    height: calc(50vh - 79px);
  }

  .right_content {
    width: 100%;
    /* position: absolute; */
    /* float: right; */
    right: 0;
    overflow: unset;
    max-height: 50vh;
    position: relative;
  }
}

@media (max-width: 950px) {
  .image1 {
    width: 145px;
    height: 85px;
  }

  /* LOGIN */

  .main_title_container {
    width: 90%;
  }

  .login_main_title {
    font-size: 60px;
    line-height: 60px;
  }

  .title_container {
    width: 100%;
  }

}

@media (max-width: 910px) {
  .team_container {
    max-height: 455px;
    margin-top: 10px;
  }

}

@media (max-width: 800px) {
  .ReactModal__Content {
    width: 80%;
  }

  .pedal_logo {
    width: 70%;
    height: auto;
    /* background-size: 100%; */
    /* background-repeat: no-repeat; */
  }

  .image1 {
    display: none
  }

  .image2 {
    display: none
  }

  .image3 {
    display: none
  }

  .image4 {
    display: none
  }

  /* LOGIN */

  .navbar_text {
    display: none;
  }


  .login_button {
    width: 40%;
  }

  .img_container {
    display: none;
  }
}

@media (max-width: 600px) {

  /* LOGIN */

  .gucci_logo_container {
    width: 150px;
    background-size: 150px;
  }

  .logo_separator {
    font-size: 40px;
    margin-top: 37px;
  }

  .unhcr_logo_container {
    height: 75px;
    width: 180px;
    background-size: 180px;
  }

  .login_button_mobile {
    width: 40%;
  }


  .photo_popup {
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 30px;
    outline: none;
    padding: 30px;
    width: 350px !important;
    height: 500px !important;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  /*
  .mask_container {
    width: 300px;
    height: 300px;
    position: absolute;
    left: 55px;
    z-index: 2;
    top: 85px;
  }
  */
  .mask_container {
    width: 427px;
    height: 310px;
    position: absolute;
    left: 40px;
    z-index: 2;
    top: 115px;
  }

  /*
  .react-html5-camera-photo {
    position: relative !important;
    width: 300px !important;
    height: 300px !important;
    left: 0px;
    z-index: 2;
    top: 0px;
  }*/
  .react-html5-camera-photo {
    position: relative !important;
    width: 351px !important;
    height: 300px !important;
    left: 0px;
    z-index: 2;
    top: 17px;
  }

  .react-html5-camera-photo>img,
  .react-html5-camera-photo>video {
    /* width: 300px !important;
    height: 300px !important;*/
    width: 435px !important;
    height: 300px !important;
  }

  #mask {
    width: 335px;
    height: 335px;
    margin-top: -300px;
  }

  .white_bg {
    width: 35px;
    height: 400px;
    position: relative;
    z-index: 2;
    background-size: contain;
    background: white;
    margin-top: -300px;
  }

  .photo_image {
    width: 335px;

    max-height: 335px;
    height: 335px;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }

  .button_retry {
    margin-top: 350px;
    margin-left: 25px;
  }

  .button_stamp {
    margin-top: 350px;
  }

  #outer-circle {
    background-color: hsl(0deg 0% 0% / 32%) !important;
    top: 36px;
    left: -40px !important;
    cursor: pointer;
  }

  /*
  @keyframes scrolling {
    from {
      transform: translate(0, 0);
    }

    to {
      transform: translate(0, 1200);

    }
  }

  .boxes_container_animation {
    animation: scrolling 2s forwards;
  }

*/
}

@media (max-width: 500px) {


  .login_button_mobile {
    width: 50%;
  }


  .login_button {
    display: none
  }

  .login_button_mobile {
    margin-top: 50px;
    display: block;
    height: fit-content;
  }
}

@media only screen and (max-width: 1850px) and (min-width: 1360px) {

  .leaderboard_column {
    display: flex;
    position: relative;
    right: 225px;

  }

  .leaderboard_container {
    margin-bottom: 100px;
  }

  .waiting_steps_img {
    margin-left: 30px;
    margin-top: 30px;
  }

  .leaderboard_users {
    height: 320px;
  }

  .mobile {
    margin-left: 10px;
    height: 410px;
  }

  .mobile_team_container {
    height: 410px;
  }

}

@media only screen and (max-height: 1000px) and (max-width: 850px) {

  /* LOGIN */

  /*
  .u424_container {
    height: 260px;
    width: 450px;
    background-size: 450px;
    position: absolute;
    bottom: 50px;
    right: 30px;
  }
  */
  .up_images_cont {

    margin-top: -100px;
  }

  .login_main_text {
    font-size: 30px;
    margin-top: 40px;
  }

  .title_container {
    width: 90%;
  }

  .logos_container {
    margin: 20px 0;
  }

  .login_button {
    margin-top: 60px;
    width: 40%;
  }

  .login_button_mobile {
    margin-top: 20px;
    width: 50%;
  }

}



@media (max-height: 900px) {

  /* LOGIN */

  /*
  .u424_container {
    height: 350px;
    width: 550px;
    background-size: 550px;
    position: absolute;
    bottom: 50px;
    right: 0px;
  }
  */

  .login_text {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .login_main_title {
    font-size: 70px;
    line-height: 70px;
  }

  .login_subtitle {
    margin-top: 20px;
  }

  .gucci_logo_container {
    width: 180px;
    background-size: 180px;
  }

  .logo_separator {
    font-size: 50px;
    margin-top: 0px;
  }

  .unhcr_logo_container {
    height: 75px;
    width: 230px;
    background-size: 230px;
  }

  /*
  .title_container {
    margin-top: 10px;
    width: 90%;
  }
  */

}

@media (max-height: 1000px) {
  .login_info_container {
    /*max-height: 83vh;*/
    overflow-y: auto;
    overflow-x: hidden;
  }

  .login_info_container::-webkit-scrollbar {
    width: 5px;
    background: #b8b8b8;
    border-radius: 32px;
  }

  .login_info_container::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 32px;
  }
}

@media only screen and (max-width: 1000px) and (max-height: 955px) {

  .leaderboard_column {
    margin-bottom: 100px;
  }
}


@media (max-height: 800px) {
  .login_info_container {
    /*max-height: 83vh;*/
    overflow-y: auto;
    overflow-x: hidden;
  }

  .activity_modal {
    height: 75vh !important;
  }

  .activity_form_container {
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .activity_form_container::-webkit-scrollbar {
    width: 5px;
    background: #b8b8b8;
    border-radius: 32px;
  }

  .activity_form_container::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 32px;
  }

}


@media (max-height: 700px) {

  .language_row {
    margin-top: 6px;
  }

  .modal_content_container {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .u424_container {
    position: relative;
    float: none;
  }

  .activity_form_container {
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal_activity_list {
    height: 60vh;
  }

  .info_popup_container {
    height: 60vh;
  }

  .activity_form_container::-webkit-scrollbar {
    width: 5px;
    background: #b8b8b8;
    border-radius: 32px;
  }

  .activity_form_container::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 32px;
  }

  .modal_content_subtitle {
    margin-top: 7px;
  }

}

@media only screen and (max-height: 800px) and (max-width: 850px) {

  /* LOGIN */


  .login_main_logo {
    width: 180px;
    background-size: 180px;
    background-repeat: no-repeat;
    background-position: center;
    height: 60px;
  }

  .logos_container {
    margin: 10px 0;
  }

  .gucci_logo_container {
    height: 50px;
    width: 150px;
    background-size: 150px;
  }

  .logo_separator {
    margin: 0 10px;
    font-size: 35px;
  }

  .unhcr_logo_container {
    height: 62px;
    width: 180px;
    background-size: 180px;
    margin: 0px;
  }

  .login_title {
    font-size: 14px;
  }

  .login_subtitle_2 {
    font-size: 14px;
  }

  .u424_container {
    height: 260px;
    width: 400px;
    background-size: 400px;
    bottom: 0px;
    right: 0px;
  }

}

@media(max-width: 600px) {
  .ReactModal__Content {
    width: 80% !important;
    min-width: 80%;
    max-height: 70vh !important;
    padding: 20px !important;
  }

}

@media(min-height: 450px) and (min-width: 600px) {
  .ReactModal__Content {

    max-height: 70vh !important;
    padding: 20px !important;
  }

}

@media (max-height: 500px) {

  .language_row {
    margin-top: 3px;
  }

  .languages_cont {
    margin-top: 10px
  }


}

/* MOBILE */

@media (max-width: 450px) {
  .countdown_columns {
    margin-left: 10px;
    margin-right: 10px;
  }

  .countdown_counter_text {
    font-size: 15px;
  }

  .confirm_create_team_button,
  .cancel_create_team_button {
    width: 120px;
  }

  .filters_container {
    display: block !important;
  }

  .filters {
    width: 300px;
    font-size: 11px;
    margin-left: 0 !important;
    justify-content: left !important;
  }

  /*
  input {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    background: transparent;
  }
*/
  input[type='checkbox'] {
    -webkit-appearance: auto;
    -webkit-border-radius: 0px;
  }

  .box_text {
    max-width: 265px;
  }

  .ppolicy_global_container {
    padding: 10px;
  }

  .ppolicy_text {
    width: 90%;
    height: 60vh;
  }

  .column {
    width: 363px;
  }

  .ReactModal__Content {
    width: 80% !important;
  }

  .info_popup_table_header {
    font-size: 10px;
  }

  .info_popup_table_cell {
    font-size: 11px;
  }

  .activity_image_container {
    width: 25px;
    height: 25px;
    background-size: 25px;
  }

  .boxes_container {
    /**/
    justify-content: 0;
    width: 95%;
    overflow-x: hidden;
  }

  .box_subtext_ngo_challenge {
    position: static;
  } 

  .left_box_line_button {
    padding: 5px 30px;
  }

  
  ul {
      padding: 0 !important;
  }


  .about_container {
    width: auto;
    height: auto;
  }

  .about_container_red {
     width: auto;
    height: auto;
  }


  .publish_container {
    width: auto;
    height: auto;
  }

  .donate_container {
    width: auto;
    height: auto;
  }

  .unhcr_challenge {
    padding: 0;
    width: auto;
    /* height: 420px; */
    margin-top: 10px;
  }

  .about_container_blu {
    width: 364px;
  }

  #u280 {
    width: 344px;
    height: 230px;
    background-size: 580px;
  }

  .under_progressbar {
    margin-top: 0px !important;
  }

  .left_box_line_publish {
    left: 125px;
  }

  .leaderboard_container {
    width: 390px;
    height: auto;
  }

  .waiting_steps_img {
    margin-left: 0px;
  }

  .name_cont {
    width: 195px;
  }

  /*
  .filters {
    width: 333px;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 10px;
    margin-left: 0px !important;
  }
*/
  .mobile {
    margin-top: 10px;
  }

  .team_container {
    width: 343px;
    margin-left: 0px;
    /*margin-bottom: 80px;*/
  }

  /*
  .user_row_width {
    width: 80%;
  }
*/
  .joined_challenge_button {
    font-size: 24px;
    padding: 10px 12px;
  }

  .login_text {
    font-size: 16px;
    line-height: 20px;
  }

  .gucci_logo_container {
    height: 60px;
    width: 120px;
    background-size: 120px;
  }

  .logos_container {
    margin: 10px 10px;
  }

  .unhcr_logo_container {
    width: 150px;
    background-size: 150px;
  }

  .login_button {
    display: none
  }

  .login_button_mobile {
    display: block;
    height: fit-content;
  }

  .login_button_text {
    font-size: 30px;
  }

  /* ADD_ACTIVITY MODAL */


  .select_activity {
    width: auto;
  }

  .input_activity {
    width: 272px;
  }

  .input_search_team_user {
    width: 272px;
    margin-top: -3px;
  }

  .date_picker_activity {
    width: 272px;
  }

  .strava_button {
    width: auto;
    margin-left: 0px;
  }

  .activity_date_icon {
    margin-left: 255px;
  }

  .confirm_add_activity_button {
    width: 35%;
  }

  .cancel_button {
    width: 35%;
  }

  .photo_popup {
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 30px;
    outline: none;
    padding: 30px;
    width: 250px !important;
    height: 500px !important;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  .button_retry {
    margin-top: 20px;
    margin-left: 5px;
    padding: 4px 25px;
    font-size: 18px;
    height: 50px;
  }

  .button_stamp {
    margin-top: 20px;
    padding: 4px 25px;
    font-size: 18px;
    height: 50px;
  }

  /*
  .mask_container {
    width: 250px;
    height: 250px;
    max-width: 250px;
    max-height: 250px;
    position: relative;
    left: 0px;
    z-index: 2;
    top: 2px;
  }
*/
  .mask_container {
    width: 275px;
    height: 327px;
    position: relative;
    left: -2px;
    z-index: 2;
    top: 25px;
  }

  .react-html5-camera-photo {
    position: relative !important;
    height: 250px !important;
    width: 260px !important;
    margin-top: 0;

    left: 0px;
    z-index: 2;
    top: 3px;
  }


  .react-html5-camera-photo,
  .react-html5-camera-photo>img,
  .react-html5-camera-photo>video {
    height: 250px !important;
    width: 260px !important;
    margin-top: 0px;
  }

  /*
  .react-html5-camera-photo,
  .react-html5-camera-photo>img,
  .react-html5-camera-photo>video {
    height: 249px !important;
    margin-top: 0;
    width: 458px !important;

  }
*/
  .photo_image {
    width: 250px;
    height: 250px;

    z-index: 10;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }

  /*
  #mask {
    width: 250px;
    height: 250px;
    max-width: 250px;
    max-height: 250px;
    margin-top: -250px;
  }
*/
  #mask {
    width: 278px;
    height: 258px;
    margin-top: -250px;
  }

  .white_bg {
    width: 5px;
    height: 400px;
    position: relative;
    z-index: 2;
    background-size: contain;
    background: white;
    margin-top: -300px;
  }

  #container-circles {
    background: #fff;
    bottom: 90px;
    height: 100px;
    left: 0 !important;
    position: absolute;
    top: 262px;
    width: 100%;
  }

  #outer-circle {
    background-color: rgba(0, 0, 0, .32) !important;
    cursor: pointer;
    left: 87px !important;
    top: 20px;
  }

  /*
  .modal_base {
    height: 73vh !important;
  }

  .modal_base_team {
    height: 80vh !important;
  }

  .modal_base_create {
    min-width: 80% !important;
  }
*/
  .unselected_button {
    width: auto;
    padding: 2px 10px 2px 10px;
  }

  .selected_button {
    width: auto;
    padding: 2px 10px 2px 10px;
  }

  .modal_activity_list_container {
    height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
  }


  .unhcr_challenge {
    padding: 0;
    width: auto;
    /* height: 420px; */
    margin-top: 10px;
  }

  .progress_bar_balloon {
    margin: 5px 10px 0px 10px;
  }

  .box_title_blu {
    font-size: 14px;

  }

  .progressBar {
    margin-bottom: 20px !important;
  }

  .about_container_blu {
    width: auto;
    height: auto;
    /*width: 364px;*/
  }

  #u280 {
    /*
    width: 364px;*/
    width: auto;
    margin-left: 0px;
    height: 230px;
    background-size: 440px;
  }

  .left_box_line_publish {
    left: 110px;
  }

  .people_circle_user {
    outline-width: 0px;
    outline-style: none;
    border: 1px solid #00a4e2;
  }

  .box_little_title {
    font-size: 10px;
    margin-left: 10px !important;
  }

  .box_little_title_2 {
    margin-left: 10px !important;
  }

  .under_progressbar {
    width: 100px;
  }

  .leaderboard_container {
    /*width: 330px !important;*/
    width: auto;

  }

  .team_container {
    width: auto;
    max-height: 430px !important;
    height: 430px;
  }

  .loader-cont-recently {
    width: 340px;
  }

  .text_center_mobile {
    width: 320px;
  }

  .user_container {
    width: 320px;
  }

  .see_button {
    height: 25px !important;
    padding-top: 3px;
  }

  /*.see_button {
    width: 150px;
  }
*/
  /*.user_rank_index {
    padding: 2px 8px;
  }*/
  .user_rank {
    margin-right: 13px;
  }

  .user_rank_text {
    padding: 0px 5px;
  }

  .user_text {
    padding: 0px 6px;
    font-size: 19px;
  }

  .name_cont_modal {
    width: 168px;
  }

  .team_box {
    width: auto;
  }

  .user_container {
    width: auto;
  }


}

/* MOBILE 2 */

@media (max-width: 380px) {
  .about_container_blu {
    /*width: auto;*/
    width: 348px;
  }

  .leaderboard_container {
    width: 329px;
  }

  .team_container {
    width: 329px;
    /*height: 329px;*/
  }

  .joined_challenge_button {
    padding: 10px 16px;
  }

  /*
  .filters {
    width: 312px;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 10px;
    margin-left: 0px !important;
  }*/
}

/* SAFARI */

@media not all and (min-resolution:.001dpcm) {

  @supports (-webkit-appearance:none) {

    @media (max-width: 450px) {


      .back_button_ppolicy {
        margin-top: 5px;
      }

      .unhcr_challenge {
        padding: 0;
        width: auto;
        /* height: 420px; */
        margin-top: 10px;
      }

      .about_container_blu {
        /*width: auto;*/
        width: 348px;
      }


      #u280 {
        /**/
        width: 348px;
        margin-left: 0px;
        height: 230px;
        background-size: 440px;
      }

      .left_box_line_publish {
        left: 110px;
      }

      .joined_challenge_button {
        line-height: 22px;
        padding: 10px 12px;
      }

      #balance {
        outline: 0px;
        border: 3px solid #000000;
      }

      #coins_earned {
        font-size: 24px;
        line-height: 24px;
      }

      .people_circle_user {
        outline-width: 0px;
        outline-style: none;
        border: 1px solid #00a4e2;
      }

      .box_little_title {
        font-size: 10px;
      }

      .under_progressbar {
        width: 100px;
      }

      .leaderboard_container {
        width: 330px !important;
      }

      .filters {
        width: 322px;
        overflow-y: hidden;
        overflow-x: auto;
        padding: 10px;
        margin-left: 0px !important;
      }

      .filters_mobile {
        margin-left: -8px !important;
      }

      .team_container {
        width: 330px !important;
        /*height: 330px;*/
      }

      /*   .see_button {
        width: 150px;
      }*/

      .user_rank_index {
        padding: 2px 8px;
      }

      .user_rank_text {
        padding: 0px 5px;
      }

      .user_text {
        padding: 0px 6px;
        font-size: 19px;
      }

      .waiting_steps_img {
        margin-left: 0px;
      }

      /*
      .selected_button{
        width: 150px !important;
        padding: 2px 10px 2px 10px;
      }
      */
      .mask_container {
        width: 275px;
        height: 327px;
        position: relative;
        left: -4px;
        z-index: 2;
        top: 25px;
      }

      .react-html5-camera-photo {
        position: relative !important;
        height: 250px !important;
        width: 260px !important;

        left: 0px;
        z-index: 2;
        top: 3px;
      }

      /*
      .react-html5-camera-photo,
      .react-html5-camera-photo>img,
      .react-html5-camera-photo>video {
        height: 250px !important;
        width: 250px !important;
        margin-top: 0px;
      }*/
      .react-html5-camera-photo,
      .react-html5-camera-photo>img,
      .react-html5-camera-photo>video {
        height: 250px !important;
        width: 260px !important;
        margin-top: 0;
      }

      .photo_image {
        width: 250px;
        height: 250px;

        z-index: 10;
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
      }

      /*
      #mask {
        width: 250px;
        height: 250px;
        max-width: 250px;
        max-height: 250px;
        margin-top: -250px;
      }
    */
      #mask {
        width: 278px;
        height: 258px;
        margin-top: -250px;
      }

      .white_bg {
        width: 5px;
        height: 400px;
        position: relative;
        z-index: 2;
        background-size: contain;
        background: white;
        margin-top: -300px;
      }

      #container-circles {
        background: #fff;
        bottom: 90px;
        height: 100px;
        left: 0 !important;
        position: absolute;
        top: 262px;
        width: 100%;
      }

      #outer-circle {
        background-color: rgba(0, 0, 0, .32) !important;
        cursor: pointer;
        left: 87px !important;
        top: 20px;
      }

    }


  }

  /*
  .unhcr_challenge {
    padding: 0;
    width: auto;
    /* height: 420px; * /
    margin-top: 10px;
  }

  .about_container_blu {
    width: 348px;
  }

  #u280 {
    width: 328px;
    height: 230px;
    background-size: 580px;
  }

  .left_box_line_publish {
    left: 110px;
  }

  .leaderboard_container {
    width: 300px;
  }
  */

}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }

}